// define a mixin object
import {
    validateTelephoneInput
} from "@/util/validateTelephoneInput.js";

export default {
    methods: {
        isTelephoneInput(e) {
            validateTelephoneInput(e);
        }
    }
};
