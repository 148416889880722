export function validateTelephoneInput(event) {
    let evt = event ? event : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (
        charCode > 32 &&
        (charCode < 40 || charCode > 57) &&
        charCode !== 35
    ) {
        evt.preventDefault();
    } else {
        return true;
    }
}