<template>
  <!-- order items modal -->
  <b-modal
    id="update-workspace-modal"
    ref="UpdateWorkspaceModal"
    title="Business Address and Contact Details"
    size="md"
    header-class="font-primary"
    header-bg-variant="light"
    header-text-variant="main-green"
    header-border-variant="white"
    footer-bg-variant="light"
    footer-border-variant="white"
    @hide="closeModal()"
  >
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-end mx-0 w-100"
      >
        <b-button
          variant="gray-550"
          pill
          class="border-0 bg-ghost-white action-btn"
          @click="cancel"
        >
          Cancel
        </b-button>
        <b-button
          variant="main-green"
          pill
          class="mt-3 ml-0 mt-sm-0 ml-sm-3 border-0 action-btn-save"
          @click="updateCurrentWorkspace()"
        >
          Save
        </b-button>
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <b-icon
        icon="x-circle"
        class="text-dark"
        @click="closeModal()"
        aria-label="Close Modal"
        font-scale="0.8"
      ></b-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100 px-1"
      no-gutters
    >
      <b-tabs
        active-nav-item-class="font-weight-bold text-main-green"
        class="customer-tabs w-100"
        nav-class="tab-wrapper"
        content-class="my-2"
      >
        <b-tab
          title="Business"
          title-link-class="tab-nav-link"
          class="px-2"
          active
        >
          <!-- full row input (Customer) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 pt-4"
            no-gutters
          >
            <b-col cols="12" sm="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Business Name *</span
              >
            </b-col>
            <b-col cols="12" sm="8" class="mt-3 mt-sm-0">
              <FormInput
                groupId="business-name-group"
                id="business-name-group-input"
                class="text-prime-gray rounded-2"
                :state="businessnameError.status"
                :invalidFeedback="`${businessnameError.message}`"
                label="Add your business name"
                v-model="businessname"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (Full name) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100"
            no-gutters
          >
            <b-col cols="12" sm="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Type Of Business *</span
              >
            </b-col>
            <b-col cols="12" sm="8" class="mt-3 mt-sm-0">
              <FormSelect
                groupId="industry-group"
                id="industry-group-input"
                class="text-prime-gray rounded-2"
                label="Choose an industry"
                :state="businessIndustryError.status"
                :invalidFeedback="`${businessIndustryError.message}`"
                v-model="businessIndustry"
                :whiteBG="false"
                :options="businessIndustryOptions"
                form="login-form"
              ></FormSelect>
            </b-col>
          </b-row>
          <!-- full row input (Phone) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100"
            no-gutters
          >
            <b-col cols="12" sm="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Business Country *</span
              >
            </b-col>
            <b-col cols="12" sm="8" class="mt-3 mt-sm-0">
              <!--              <FormInput-->
              <!--                groupId="business-country-group"-->
              <!--                id="business-country-group-input"-->
              <!--                class="text-prime-gray rounded-2"-->
              <!--                :state="businessCountryError.status"-->
              <!--                :invalidFeedback="`${businessCountryError.message}`"-->
              <!--                label="Choose a country"-->
              <!--                v-model="businessCountry"-->
              <!--                isRequired-->
              <!--                :whiteBG="false"-->
              <!--                trim-->
              <!--                :isDisabled="true"-->
              <!--                form="login-form"-->
              <!--                type="text"-->
              <!--              ></FormInput>-->
              <FormSelect
                groupId="industry-group"
                id="industry-group-input"
                class="text-prime-gray rounded-2 mt-2"
                label="Choose a country"
                :state="businessCountryError.status"
                :invalidFeedback="`${businessCountryError.message}`"
                v-model="businessCountry"
                :whiteBG="false"
                :options="countryList"
                form="login-form"
              ></FormSelect>
            </b-col>
          </b-row>
          <!-- full row input (E-mail) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100"
            no-gutters
          >
            <b-col cols="12" sm="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Currency *</span
              >
            </b-col>
            <b-col cols="12" sm="8" class="mt-3 mt-sm-0">
              <!--              <FormInput-->
              <!--                groupId="currency-group"-->
              <!--                id="currency-group-input"-->
              <!--                class="text-prime-gray rounded-2"-->
              <!--                :state="currencyError.status"-->
              <!--                :invalidFeedback="`${currencyError.message}`"-->
              <!--                label="Sri Lankan Rupees"-->
              <!--                v-model="currency"-->
              <!--                isRequired-->
              <!--                :whiteBG="false"-->
              <!--                :isDisabled="true"-->
              <!--                trim-->
              <!--                form="login-form"-->
              <!--                type="text"-->
              <!--              ></FormInput>-->
              <FormSelect
                groupId="industry-group"
                id="industry-group-input"
                class="text-prime-gray rounded-2 mt-2"
                label="Choose a currency"
                :state="currencyError.status"
                :invalidFeedback="`${currencyError.message}`"
                v-model="currency"
                :whiteBG="false"
                :options="currencyList"
                form="login-form"
              ></FormSelect>
            </b-col>
          </b-row>
          <!-- full row input (E-mail) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100"
            no-gutters
          >
            <b-col cols="12">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Language</span
              >
            </b-col>
            <b-col cols="12" sm="8" class="mt-3 mt-sm-0">
              <FormInput
                groupId="language-group"
                id="language-group-input"
                class="text-prime-gray rounded-2"
                :state="languageError.status"
                :invalidFeedback="`${languageError.message}`"
                label="Choose a language"
                v-model="language"
                isRequired
                :whiteBG="false"
                :isDisabled="true"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (E-mail) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100"
            no-gutters
          >
            <b-col cols="12">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Time Zone</span
              >
            </b-col>
            <b-col cols="12" sm="8" class="mt-3 mt-sm-0">
              <FormSelect
                groupId="timezone-group"
                id="timezone-group-input"
                class="text-prime-gray rounded-2"
                :state="timezoneError.status"
                :invalidFeedback="`${timezoneError.message}`"
                label="Greenwich Mean Time (GMT)"
                v-model="timezone"
                isRequired
                :whiteBG="false"
                :options="filteredTimezoneList"
                trim
                form="login-form"
                type="text"
              ></FormSelect>
            </b-col>
          </b-row>
          <!-- full row input (E-mail) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100"
            no-gutters
          >
            <b-col cols="12">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Fiscal Year</span
              >
            </b-col>
            <b-col cols="12" sm="8" class="mt-3 mt-sm-0">
              <FormSelect
                groupId="fiscalyear-group"
                id="fiscalyear-group-input"
                class="text-prime-gray rounded-2"
                :state="fiscalyearError.status"
                :invalidFeedback="`${fiscalyearError.message}`"
                :label="fiscalyear"
                v-model="fiscalyear"
                :whiteBG="false"
                :options="fiscalyearOptions"
                form="login-form"
              ></FormSelect>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Address" title-link-class="tab-nav-link" class="px-2">
          <!-- full row input (No :) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 pt-4"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >No :</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="Number-name-group"
                id="Number-name-group-input"
                class="text-prime-gray rounded-2"
                label="Number"
                v-model="addressNum"
                :state="addressNumError.status"
                :invalidFeedback="addressNumError.message"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (Street) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Street</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="Street-name-group"
                id="Street-name-group-input"
                class="text-prime-gray rounded-2"
                label="Street"
                v-model="addressStreet"
                :state="addressStreetError.status"
                :invalidFeedback="addressStreetError.message"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (City) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >City</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="City-name-group"
                id="City-name-group-input"
                class="text-prime-gray rounded-2"
                label="City"
                v-model="addressCity"
                :state="addressCityError.status"
                :invalidFeedback="addressCityError.message"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (Zip Code) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Zip Code</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="Zip-name-group"
                id="Zip-name-group-input"
                class="text-prime-gray rounded-2"
                label="Zip Code"
                v-model="addressZip"
                :state="addressZipError.status"
                :invalidFeedback="addressZipError.message"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="number"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (Province) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Country</span
              >
            </b-col>
            <b-col cols="8">
              <FormSelect
                groupId="industry-group"
                id="industry-group-input"
                class="text-prime-gray rounded-2 mt-2"
                label="Choose a country"
                :state="businessCountryError.status"
                :invalidFeedback="`${businessCountryError.message}`"
                v-model="businessCountry"
                :whiteBG="false"
                :options="countryList"
                form="login-form"
              ></FormSelect>
            </b-col>
          </b-row>
          <!-- full row input (Country) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Province</span
              >
            </b-col>
            <b-col cols="8">
              <FormSelect
                groupId="provincefiscalyear-group"
                id="provincefiscalyear-group-input"
                class="text-prime-gray rounded-2"
                label="Province"
                v-model="addressProvince"
                :state="addressProvinceError.status"
                :invalidFeedback="addressProvinceError.message"
                :whiteBG="false"
                :options="filteredProvinceList"
                form="login-form"
              ></FormSelect>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Contact" title-link-class="tab-nav-link" class="px-2">
          <!-- full row input (No :) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 pt-4"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Phone</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="Phone-name-group"
                id="Phone-name-group-input"
                class="text-prime-gray rounded-2"
                label="Phone"
                v-model="customerPhone"
                :state="customerPhoneError.status"
                :invalidFeedback="customerPhoneError.message"
                isRequired
                @keypress="isTelephoneInput($event)"
                :whiteBG="false"
                trim
                form="login-form"
                type="tel"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (No :) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Mobile</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="Mobile-name-group"
                id="Mobile-name-group-input"
                class="text-prime-gray rounded-2"
                label="Mobile"
                v-model="customerMobile"
                :state="customerMobileError.status"
                :invalidFeedback="customerMobileError.message"
                isRequired
                @keypress="isTelephoneInput($event)"
                :whiteBG="false"
                trim
                form="login-form"
                type="tel"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (No :) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Email</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="Email-name-group"
                id="Email-name-group-input"
                class="text-prime-gray rounded-2"
                :state="customerEmailError.status"
                :invalidFeedback="customerEmailError.message"
                label="Email"
                v-model="customerEmail"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (No :) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Website</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="Website-name-group"
                id="Website-name-group-input"
                class="text-prime-gray rounded-2"
                label="Website URL"
                v-model="customerWebsite"
               
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
              <!-- :state="customerWebsiteError.status"
                :invalidFeedback="customerWebsiteError.message"
                isRequired -->
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-row>
  </b-modal>
</template>

<script>
// components
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import CheckEmail from "@/util/CheckEmail";

import isValidContactNumber from "@/util/isValidContactNumber";

import isTelephoneInput from "@/mixin/isTelephoneInput.js";
// services
import {
  GetCurrentWorkspace,
  UpdateCurrentWorkspace,
} from "@/services/settingsbusiness.service";

export default {
  name: "UpdateCurrentWorkspaceModal",
  props: {
    itemContent: {
      default: null,
    },
  },
  mixins: [isTelephoneInput],

  components: {
    FormInput,
    FormSelect,
  },
  data() {
    return {
      businessname: undefined,
      businessIndustry: undefined,
      // businessDescription: null,
      businessCountry: "New Zealand",
      countryList: ["New Zealand", "Australia","Fiji"],
      businessIndustryOptions: [
        "Art, Photography & Creative Services",
        "Construction & Home Improvement",
        "Consulting & Professional Services",
        "Financial Services & Insurance",
        "Hair, Spa & Aesthetics",
        "Medical, Dental & Health",
        "Non-profits, Associations & Groups",
        "Real Estate",
        "Retailers, Resellers & Sales",
        "Web, Tech & Media",
        "Travel & Tourism",
        "Other: I make or sell a product",
        "Other: I provide a service",
      ],
      // businessDescriptionOptions: [
      //   "Yeah, i m running it part-time",
      //   "Yeah, i m running it full-time",
      //   "Not yet, will launch it soon",
      // ],
      // businessCountryOptions: ["Sri Lanka", "United Kingdom"],
      currency: "NZD",
      currencyList: ["NZD", "FJD", "AUD", "USD"],
      language: "English",
      newzealandTimeoneOption: ["New Zealand Standard Time GMT+12"],
      fijiTimeoneOption: ["Fiji Standard Time (GMT +12)"],
      fijiProvinceList:["Ba","Bua","Cakaudrove","Kadavu","Lau","Lomaiviti","Macuata","Nadroga-Navosa",
        "Naitasiri","Namosi","Ra","Rewa","Serua","Tailevu"],
      australiaTimeoneOption: [
        "Australian Eastern Standard Time (AEST) GMT+10",
        "Australian Central Standard Time (ACST) GMT + 9.5",
        "Australian Western Standard Time (AWST) GMT +8"
        ],
      fiscalyear: undefined,
      fiscalyearOptions: ["January - December", "April - March","July - June"],
      addressNum: undefined,
      addressStreet: undefined,
      addressCity: undefined,
      addressZip: undefined,
      addressProvince: undefined,
      addressCountry: "New Zealand",
      customerPhone: undefined,
      customerMobile: undefined,
      customerEmail: undefined,
      customerWebsite: undefined,
      NewzelandProvinceList: [
        "Northland",
        "Auckland",
        "Waikato",
        "Bay of Plenty",
        "Gisborne",
        "Hawke's Bay",
        "Taranaki",
        "Manawatu-Whanganui",
        "Wellington",
        "Tasman",
        "Nelson",
        "Marlborough",
        "West Coast",
        "Canterbury",
        "Otago",
        "Southland",
      ],
      AusiProvinceList: [
        "New South Wales",
        "Queensland",
        "Northern Territory",
        "Western Australia",
        "South Australia",
        "Victoria",
        "Australian Capital Territory",
        "Tasmania",
      ],
      currencyError: {
        status: null,
        message: "",
      },
      languageError: {
        status: null,
        message: "",
      },
      timezoneError: {
        status: null,
        message: "",
      },
      fiscalyearError: {
        status: null,
        message: "",
      },
      businessnameError: {
        status: null,
        message: "",
      },
      businessIndustryError: {
        status: null,
        message: "",
      },
      // businessDescriptionError: {
      //   status: null,
      //   message: "",
      // },
      businessCountryError: {
        status: null,
        message: "",
      },
      customerEmailError: {
        status: null,
        message: "",
      },
      addressNumError: {
        status: null,
        message: "",
      },
      addressStreetError: {
        status: null,
        message: "",
      },
      addressCityError: {
        status: null,
        message: "",
      },
      addressZipError: {
        status: null,
        message: "",
      },
      addressProvinceError: {
        status: null,
        message: "",
      },
      customerPhoneError: {
        status: null,
        message: "",
      },
      customerMobileError: {
        status: null,
        message: "",
      },
      customerWebsiteError: {
        status: null,
        message: "",
      },
    };
  },
  watch: {
    customerEmail(value) {
      if (value) {
        if (CheckEmail(value) == true) this.customerEmailError.status = true;
        else {
          this.customerEmailError.status = false;
          this.customerEmailError.message =
            "Please enter a valid email address";
        }
      } else {
        this.customerEmailError.status = true;
        // this.customerEmailError.message = "Please fill in your email address";
      }
    },
    businessname(val) {
      if (val) {
        this.businessnameError.status = true;
      } else {
        this.businessnameError.status = false;
        this.businessnameError.message = "Fill in the Business Name";
      }
    },
    businessIndustry(val) {
      if (val) {
        this.businessIndustryError.status = true;
      } else {
        this.businessIndustryError.status = false;
        this.businessIndustryError.message = "Fill in the Business Industry";
      }
    },
     businessCountry(value) {
      if (value) {
        this.businessCountryError.status = true;
        this.businessCountry = value;
        this.validatePhoneNumber(this.customerPhone, value);
        this.validateMobileNumber(this.customerMobile, value);
      } else {
        this.businessCountryError.status = false;
        this.businessCountryError.message = "Please fill out this field";
      }
    },
    fiscalyear(val) {
      if (val) {
        this.fiscalyearError.status = true;
      } else {
        this.fiscalyearError.status = false;
        this.fiscalyearError.message = "Fill in the Time Zone";
      }
    },
    addressNum(val) {
      if (val) {
        this.addressNumError.status = true;
      } else {
        this.addressNumError.status = false;
        this.addressNumError.message = "Fill in the Address Number";
      }
    },
    addressStreet(val) {
      if (val) {
        this.addressStreetError.status = true;
      } else {
        this.addressStreetError.status = false;
        this.addressStreetError.message = "Fill in the Street";
      }
    },
    addressCity(val) {
      if (val) {
        this.addressCityError.status = true;
      } else {
        this.addressCityError.status = false;
        this.addressCityError.message = "Fill in the City";
      }
    },
    addressZip(val) {
      if (val) {
        this.addressZipError.status = true;
      } else {
        this.addressZipError.status = false;
        this.addressZipError.message = "Fill in the Zip Code";
      }
    },
    addressProvince(val) {
      if (val) {
        this.addressProvinceError.status = true;
      } else {
        this.addressProvinceError.status = false;
        this.addressProvinceError.message = "Fill in the Province";
      }
    },
    customerPhone(value) {
      this.validatePhoneNumber(value);
    },
    customerMobile(value) {
      this.validateMobileNumber(value);
    },
    // customerWebsite(val) {
    //   if (val) {
    //     this.customerWebsiteError.status = true;
    //   } else {
    //     this.customerWebsiteError.status = false;
    //     this.customerWebsiteError.message = "Fill in the Website URL";
    //   }
    // },
    // itemContent(val) {
    //   console.log("item content myBusiness", val);
    //   if (val) {
    //     this.businessname = val.name;
    //     this.businessIndustry = val.type;
    //     this.fiscalyear = val.fiscal_year;
    //     this.customerPhone = val.phone;
    //     this.customerMobile = val.mobile;
    //     this.customerEmail = val.email;
    //     this.customerWebsite = val.website;
    //   } else {
    //     this.fiscalyear = null;
    //   }
    // },
  },
  computed: {
    filteredProvinceList() {
      if (this.businessCountry === "New Zealand") {
        return this.NewzelandProvinceList;
      } else if (this.businessCountry === "Australia") {
        return this.AusiProvinceList;
      } else if (this.businessCountry === "Fiji") {
        return this.fijiProvinceList;
      } else {
        return [];
      }
    },
    filteredTimezoneList(){
      if (this.businessCountry === "New Zealand") {
        return this.newzealandTimeoneOption;
      } else if (this.businessCountry === "Australia") {
        return this.australiaTimeoneOption;
      } else if (this.businessCountry === "Fiji") {
        return this.fijiTimeoneOption;
      } else {
        return [];
      }
    },
  },
  async mounted() {
    await this.initFn();
  },
  methods: {
    validatePhoneNumber(value) {
      this.customerPhoneError.status = null;
        if (!value && !this.businessCountry) {
          this.customerPhoneError.status = false;
          this.customerPhoneError.message = "Please fill in the contact number";
        } else{
          let ans = isValidContactNumber(value, this.businessCountry);
          if(ans == true){
            this.customerPhoneError.status = true;
          }
          else{
            this.customerPhoneError.status = false;
            this.customerPhoneError.message = "Invalid contact number";
          }
        }
    },
    validateMobileNumber(value){
      this.customerMobileError.status = null;
        if (!value && !this.businessCountry) {
          this.customerMobileError.status = false;
          this.customerMobileError.message = "Please fill in the contact number";
        } else{
          let ans = isValidContactNumber(value, this.businessCountry);
          if(ans == true){
            this.customerMobileError.status = true;
          }
          else{
            this.customerMobileError.status = false;
            this.customerMobileError.message = "Invalid contact number";
          }
        }
    },
    closeModal() {
      this.$emit("onClose");
    },
    async initFn() {
      try {
        let { data } = await GetCurrentWorkspace();
        console.log("workspace modal get data", data);
        this.businessname = data.name;
        this.businessIndustry = data.type;
        this.fiscalyear = data.fiscal_year;
        this.addressNum = data.address.number;
        this.addressStreet = data.address.street;
        this.addressCity = data.address.city;
        this.addressZip = data.address.zipcode;
        this.addressProvince = data.address.province;
        this.customerPhone = data.phone;
        this.customerMobile = data.mobile;
        this.customerEmail = data.email;
        this.customerWebsite = data.website;
        this.businessCountry = data.country;
        this.currency = data.currency;
      } catch (error) {
        console.log(error);
      }
    },
    async updateCurrentWorkspace() {
      let ans1 = isValidContactNumber(this.customerPhone, this.businessCountry);
      let ans2 = isValidContactNumber(this.customerMobile, this.businessCountry);
      if (
        this.businessname &&
        this.businessIndustry &&
        this.fiscalyear &&
        this.addressNum &&
        this.addressStreet &&
        this.addressCity &&
        this.addressZip &&
        this.addressProvince &&
        // this.customerPhone &&
        // this.customerMobile &&
        // this.customerEmail &&
        // this.customerWebsite &&
        this.businessCountry &&
        this.currency &&
        ans1 == true &&
        ans2 == true
      ) {
        let payload = {
          name: this.businessname,
          type: this.businessIndustry,
          fiscal_year: this.fiscalyear,
          address: {
            number: this.addressNum,
            street: this.addressStreet,
            city: this.addressCity,
            zipcode: this.addressZip,
            province: this.addressProvince,
          },
          phone: this.customerPhone,
          mobile: this.customerMobile,
          email: this.customerEmail,
          website: this.customerWebsite,
          country: this.businessCountry,
          currency: this.currency,
        };
        console.log("workspace payload", payload);
        try {
          let { data, status } = await UpdateCurrentWorkspace(payload);
          if (status == 200) {
            console.log("workspace updated", data);
            this.$emit("reloadFn");
            let payloadNotify = {
              isToast: true,
              title: "Business Settings Update",
              content:
                "The current business settings have been updated successfully",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        this.errorFn();
      }
    },
    errorFn() {
      //business details
      if (!this.businessname) {
        this.businessnameError.status = false;
        this.businessnameError.message = "Please fill in the Business Name";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update Business Settings",
          content: "Please enter the the Business Name",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.businessIndustry) {
        this.businessIndustryError.status = false;
        this.businessIndustryError.message =
          "Please fill in the Type Of Business";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update Type Of Business",
          content: "Please enter the the Type Of Business",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.fiscalyear) {
        this.fiscalyearError.status = false;
        this.fiscalyearError.message = "Please fill in the Fiscal Year";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update Fiscal Year",
          content: "Please enter the the Fiscal Year",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      // address details
      if (!this.addressNum) {
        this.addressNumError.status = false;
        this.addressNumError.message = "Please fill in the Address Number";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update Address Number",
          content: "Please enter the the Address Number",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.addressStreet) {
        this.addressStreetError.status = false;
        this.addressStreetError.message = "Please fill in the Street";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update Street",
          content: "Please enter the the Street",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.addressCity) {
        this.addressCityError.status = false;
        this.addressCityError.message = "Please fill in the City";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update City",
          content: "Please enter the the City",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.addressZip) {
        this.addressZipError.status = false;
        this.addressZipError.message = "Please fill in the Zip Code";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update Zip Code",
          content: "Please enter the the Zip Code",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.addressProvince) {
        this.addressProvinceError.status = false;
        this.addressProvinceError.message = "Please fill in the Province";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update Province",
          content: "Please enter the the Province",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.customerPhone) {
        this.customerPhoneError.status = false;
        this.customerPhoneError.message = "Please fill in the Phone";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update Phone",
          content: "Please enter the the Phone",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.customerMobile) {
        this.customerMobileError.status = false;
        this.customerMobileError.message = "Please fill in the Mobile";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update Mobile",
          content: "Please enter the the Mobile",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.customerEmail) {
        this.customerEmailError.status = false;
        this.customerEmailError.message = "Please fill in the Email";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update Email",
          content: "Please enter the the Email",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      // if (!this.customerWebsite) {
      //   this.customerWebsiteError.status = false;
      //   this.customerWebsiteError.message = "Please fill in the Website URL";
      //   let payloadNotify = {
      //     isToast: true,
      //     title: "Error: Unable to Update Website URL",
      //     content: "Please enter the the Website",
      //     variant: "danger",
      //   };
      //   this.$store.dispatch("notification/setNotify", payloadNotify);
      // }
      let ans1 = isValidContactNumber(this.customerPhone, this.businessCountry);
      if(ans1 == false){
        this.customerPhoneError.status = false;
        this.customerPhoneError.message = "Invalid contact number";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to save the Customer",
          content: "Contact number does not match with the country",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      let ans2 = isValidContactNumber(this.customerMobile, this.businessCountry);
      if(ans2 == false){
        this.customerMobileError.status = false;
        this.customerMobileError.message = "Invalid contact number";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to save the Customer",
          content: "Contact number does not match with the country",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
  },
};
</script>

<style scoped>
.tab-wrapper {
  border-bottom: 2px solid var(--gray-750);
  /* border-bottom: none; */
}
.customer-tabs {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
}
.customer-tabs >>> .tab-nav-link {
  font-size: 0.9rem;
  padding-right: 20px;
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--gray-750);
  /* border-bottom: 1px solid var(--prime-gray); */
}
.action-btn {
  height: 50px;
  min-width: 120px;
}

.action-btn-save {
  height: 50px;
  min-width: 120px;
}

.action-btn-save:hover {
  background: #0B9B7D;
  box-shadow: inset 0px 0px 10px #ffffff;
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
